<template>
  <div class="check_container">
    <el-row class="check_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <el-row class="outpatient_tip">
      <h1>温馨提示：点击可查看体检结果</h1>
    </el-row>
    <div class="check_main">
      <div class="info">
        <div class="cardno">
          卡号：
          <span>{{ patCardNo }}</span>
        </div>
        <div class="cardno">
          就诊人：
          <span>{{ patName }}</span>
        </div>

        <!-- <div class="block">
          <span class="demonstration">选择日期：</span>
          <el-date-picker v-model="value"
                          type="date"
                          :picker-options="pickerOptions"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          @change="selectTime"
                          :editable="editable"
                          :clearable="clearable"></el-date-picker>
        </div> -->
      </div>
      <el-table :data="tableData" v-loading="loading" border="" height="90%" style="width: 100%" ref="multipleTable" @row-click="on_select">
        <el-table-column label="体检名称" prop="crtDate">
          <template slot-scope="scope">
            <span>{{ scope.row.packageName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="studyId">
          <template slot-scope="scope">
            <span>{{ scope.row.patName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.patSex }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报告时间">
          <template slot-scope="scope">
            <span>{{ scope.row.reportTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报告号">
          <template slot-scope="scope">
            <span>{{ scope.row.reportNo }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="btn">
        <el-button type="primary" class="checkAll" @click.native="checkAll">{{ msg }}</el-button>
        <el-button type="danger" class="print" :loading="isPrint" @click="getTojpg()">打印</el-button>
      </div> -->
    </div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <el-button type="primary" @click.native="back" class="cheakBack">返回</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from '@/baseAPI'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      editable: false,
      clearable: false,
      isPrint: false,
      tableData: [],
      multipleSelection: [],
      value: '',
      msg: '全选',
      loading: false,
      user: '',
      patCardNo: '',
      cardNo: '',
      patName: ''
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patCardNo = this.$route.query.patCardNo
    this.cardNo = this.$route.query.cardNo
    this.patName = this.$route.query.patName
    this.value = this.formatTime(0)
    this.getUserPacsInfo()
  },
  methods: {
    on_select(val) {
      //点击行选中复选框
      const params = {
        reportNo: val.reportNo
      }
      this.$router.push({
        path: '/peExaminList',
        query: params
      })
    },
    async getUserPacsInfo() {
      this.loading = true
      let _this = this
      // const params = {
      //   cardNo: "440721197508290031"
      //   // cardNo: this.patCardNo
      // }
      // const { data: res } = await formPost(`/pe/peReportList?cardNo=${params.cardNo}`)
      const { data: res } = await jsonPost('/pe/peReportList', {
        cardNo: this.patCardNo
      })
      if (res.code === 0) {
        this.loading = false
        this.tableData = res.data.item
      } else {
        this.loading = false
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    selectTime(e) {
      console.log(e)
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear()
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      return [y, m, d].map(this.padStartConvert).join('-')
    },

    checkAll() {
      this.$refs.multipleTable.toggleAllSelection()
      if (this.$refs.multipleTable.selection.length === 0) {
        this.msg = '全不选'
      } else if (this.$refs.multipleTable.selection.length === this.tableData.length) {
        this.msg = '全选'
      }
    }
  }
}
</script>
<style>
.check_container {
  width: 100%;
  height: 100%;
}

.check_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.check_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.check_container .el-input__inner {
  border: 2px solid #000 !important;
  font-size: 24px !important;
}

.el-picker-panel__shortcut {
  line-height: 60px !important;
  font-size: 30px !important;
  text-align: center !important;
  padding: 0 !important;
}

.el-date-picker__header-label {
  font-size: 30px !important;
  line-height: 36px !important;
}

.el-picker-panel__icon-btn {
  font-size: 30px !important;
}

.el-date-table {
  font-size: 40px !important;
}

.el-date-table td {
  width: 50px !important;
  height: 50px !important;
  font-size: 20px !important;
}

.el-date-table td div {
  height: 50px !important;
}

.el-date-table td span {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}

.el-picker-panel {
  position: fixed !important;
  left: 670px !important;
}

.el-date-picker.has-sidebar {
  width: 560px !important;
}

.el-date-picker .el-picker-panel__content {
  width: 95% !important;
}

.el-checkbox__inner {
  border: 2px solid gray !important;
}

.el-month-table,
.el-year-table {
  font-size: 20px !important;
}

.check_main {
  position: relative;
  margin: 0 auto;
  margin-top: 10%;
  width: 90%;
  height: 50%;
}

.check_main .info {
  text-align: center;
  font-size: 24px;
  color: #409eff;
}

.outpatient_tip {
  margin-top: 45%;
}

.outpatient_tip h1 {
  margin: 30px 0;
  font-weight: 400;
  color: red;
  text-align: center;
  font-size: 40px;
}

.check_main .info .cardno,
.check_main .info .block {
  display: inline-block;
  padding: 20px 160px;
}

.check_main .el-table {
  margin-top: 20px;
}

.check_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.check_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.check_container .el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
  margin-top: -7px !important;
  margin-left: 20px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 8px !important;
}

.check_main .btn {
  margin-top: 20px;
  text-align: center;
}

.check_main .btn .el-button {
  font-size: 30px !important;
  margin: 0 50px !important;
}

.cheakBack {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
